'use client';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FiLoader } from 'react-icons/fi';

const schema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  email: z.string().email({ message: 'Invalid email address' }),
  mobile: z.string().min(10, { message: 'Mobile number is required' }),
  course: z.string().min(1, { message: 'Course selection is required' }),
  city: z.string().min(1, { message: 'City selection is required' }),
  join: z.string().min(1, { message: 'Plan to join selection is required' }),
});

const Intro = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await fetch('/api/popup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setMessage('Message sent successfully. Thank you!');
        reset();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 px-4">
      <div className="bg-white shadow-lg rounded-xl p-8 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">
          Enroll Now
        </h2>

        {message && (
          <p className="text-green-600 text-center font-medium mb-4">
            {message}
          </p>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Input Fields */}
          <div className="space-y-4">
            <div>
              <input
                {...register('name')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                placeholder="Your Name"
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <input
                {...register('email')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                placeholder="Your Email"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div>
              <input
                {...register('mobile')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                placeholder="Your Phone"
              />
              {errors.mobile && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.mobile.message}
                </p>
              )}
            </div>

            <div>
              <select
                {...register('course')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-blue-500 outline-none"
              >
                <option value="">Select Course</option>
                <option value="Animation">Animation</option>
                <option value="Graphics">Graphics</option>
                <option value="VFX">VFX</option>
                <option value="Web Design">Web Design</option>
                <option value="Gaming">Gaming</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Interior & Architecture">
                  Interior & Architecture
                </option>
                <option value="Jewellery">Jewellery</option>
                <option value="Mechanical">Mechanical</option>
                <option value="Civil">Civil</option>
                <option value="Fashion">Fashion</option>
                <option value="Digital Media">Digital Media</option>
                <option value="Broadcast Media">Broadcast Media</option>
                <option value="Photography">Photography</option>
                <option value="Hobby Courses">Hobby Courses</option>
              </select>
              {errors.course && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.course.message}
                </p>
              )}
            </div>

            <div>
              <select
                {...register('city')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-blue-500 outline-none"
              >
                <option value="">Select City</option>
                <option value="Mulund">Mulund</option>
                <option value="Ghatkopar">Ghatkopar</option>
                <option value="Chembur">Chembur</option>
                <option value="Dadar">Dadar</option>
                <option value="Charniroad">Charniroad</option>
                <option value="Malad">Malad</option>
                <option value="Andheri">Andheri</option>
                <option value="Bhayander">Bhayander</option>
                <option value="Virar">Virar</option>
                <option value="Thane">Thane</option>
                <option value="Dombivli">Dombivli</option>
                <option value="Vashi">Vashi</option>
                <option value="Panvel">Panvel</option>
                <option value="Viman Nagar(Pune)">Viman Nagar (Pune)</option>
              </select>
              {errors.city && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.city.message}
                </p>
              )}
            </div>

            <div>
              <select
                {...register('join')}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-white focus:ring-2 focus:ring-blue-500 outline-none"
              >
                <option value="">Choose</option>
                <option value="Immediately">Immediately</option>
                <option value="Next Month">Next Month</option>
                <option value="Just Exploring">Just Exploring</option>
              </select>
              {errors.join && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.join.message}
                </p>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-3 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? <FiLoader className="animate-spin mr-2" /> : null}
            {isSubmitting ? 'Sending...' : 'SEND MESSAGE'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Intro;
