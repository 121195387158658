'use client';
import { useState, useEffect } from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import Image from 'next/image';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Courses', href: '/courses' },
  { name: 'Students Zone', href: '/students-zone' },
  { name: 'Placements', href: '/job-placement' },
  { name: 'Franchise', href: '/franchise' },
  { name: 'Blog', href: '/blog' },
  { name: 'Contact Us', href: '/contact-us' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.submenu')) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-medium tracking-wider bg-white font-sans">
      <div className="max-w-screen-xl mx-5 lg:mx-auto py-3">
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 items-center justify-center">
          <div className="col-span-1 md:col-span-2 lg:col-span-1">
            <Link href="/">
              <Image
                src="/images/logo.png"
                alt="logo"
                width={103}
                height={92}
                className="lg:mx-0 mx-auto w-28 h-28 lg:w-28 lg:h-28"
              />
            </Link>
          </div>
          <div className="col-span-2 lg:col-span-2 lg:pr-8">
            <div className="flex-none lg:flex items-center justify-between">
              <div className="flex flex-row sm:flex-row items-center sm:items-start text-center sm:text-left">
                <div className="bg-blue-800 rounded-lg p-2 self-center mr-3">
                  <FaPhoneAlt className="w-4 h-4 lg:w-8 lg:h-8 text-white" />
                </div>
                <div className="text-neutral-600 text-left">
                  <p className="mb-0 font-normal text-sm ">Call Now</p>
                  <p className="mb-0">
                    <Link
                      href="tel:+919892266692"
                      className="font-bold text-black"
                    >
                      +91 9892 266692
                    </Link>
                  </p>
                </div>
              </div>

              {/* Email Us */}
              <div className="flex flex-row sm:flex-row items-center sm:items-start text-center sm:text-left mt-2">
                <div className="bg-blue-800 rounded-lg p-2 flex justify-center items-center mr-3">
                  <FaEnvelope className="w-4 h-4 lg:w-8 lg:h-8 text-white" />
                </div>
                <div className="text-neutral-600 text-left">
                  <p className="mb-0 font-normal">Email Us</p>
                  <p className="mb-0">
                    <Link
                      href="maito:walkineducate@gmail.com"
                      className="font-bold text-black"
                    >
                      walkineducate@gmail.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:block hidden col-span-2 md:col-span-1 lg:col-span-1">
            <a
              href="/free-counselling"
              className="btn-theme rounded-lg text-white py-3 px-6 lg:px-8 text-center"
            >
              Free Counselling
            </a>
          </div>
        </div>
      </div>

      <Disclosure as="nav" className="bg-blue-800">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-xl mx-1 lg:mx-auto py-3">
              <div className="relative flex items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-8 w-8" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center mx-5 justify-end sm:items-center sm:justify-start">
                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex md:space-x-6 lg:space-x-14 justify-center">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-white lg:text-lg px-3 py-2 rounded-md font-medium flex items-center hover:text-orange-400"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? 'transform rotate-180'
                                    : ''
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-56">
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-orange-400"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-white px-0 py-2 rounded-md lg:text-lg font-medium hover:text-orange-400"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>

                  {/* Button */}
                  <div className="block lg:hidden  flex justify-end     ">
                    <a
                      href=""
                      className="btn-theme rounded-lg text-white py-3 px-6 lg:px-8 text-center"
                    >
                      Free Counselling
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 py-6 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? 'transform rotate-180'
                                  : ''
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? 'block' : 'hidden'
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-white px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
