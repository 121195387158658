"use client";
import Header from "@/components/Header";
import { useEffect, useState } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";

const StickyHeader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = () => {
    if (typeof window !== "undefined") {
      // Check the scroll position
      const currentScrollY = window.scrollY;

      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header
      } else {
        setIsVisible(true); // Show header
      }

      setLastScrollY(currentScrollY); // Update the last scroll position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed1 top-01 w-full bg-transparent shadow-md21 transition-transform duration-300 ease-in-out z-[999] ${
        !isVisible && "-translate-y-full"
      }`}
    >
      {/* <section className=" bg-color-carbon-500 text-base font-light">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-3 items-center justify-between font-medium text-white h-12">
            <div className="md:col-span-3 lg:col-span-3 pr-2 flex items-center">
              <div className="flex mr-10"><FaPhone className="self-start mt-1 mr-1" size={18} /> +91 9892 266692</div>
              <div className="flex"><FaEnvelope className="self-start mt-1 mr-1" size={18} />info@walkineducate.com</div>
            </div>
            <div className="col-span-1 md:col-span-1 lg:col-span-1">
              <div className="flex items-center justify-end space-x-2">
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#" ><FaFacebook size={18} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#"><FaTwitter size={18} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#"><FaInstagram size={18} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#"><FaLinkedin size={18} /></a>
                <a className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2" href="#"><FaYoutube size={18} /></a>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-1 ">
              <a href="#" className=" bg-red-700 text-white py-3 lg:py-4 px-6 lg:px-8 items-center justify-center">Free Counselling</a>
            </div>
          </div>
        </div>
      </section> */}
      <Header />
    </div>
  );
};

export default StickyHeader;
