'use client';
import React from 'react';
import Image from 'next/image';
import Modal from 'react-modal';
import PopupForm from './PopupForm';

const Popup = ({
  isVisible,
  onClose,
  title = '',
  buttonText = 'Submit',
  consentText = 'text-gray-700',
}) => {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      ariaHideApp={false} // Prevents accessibility errors in Next.js
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9998,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
          textAlign: 'left',
          border: '1px solid #ccc',
          zIndex: 9999,
        },
      }}
    >
      <div className="flex items-center justify-center md:w-full p-0 m-0 overflow-hidden relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 px-4 py-2 bg-blue-500 text-white rounded-lg z-20"
        >
          X
        </button>

        {/* Modal Content */}
        <div className="flex items-center justify-center bg-gray-200">
          <div className="bg-white shadow-lg rounded-lg flex lg:min-w-[50rem]">
            {/* Left - Image Section */}
            <div className="relative w-full md:w-3/7 bg-gray-800">
              <Image
                src="/images/bright_eyed_indian_student_warm_smile.jpg"
                alt="Student with a warm smile"
                fill
                className="absolute object-cover rounded-l-lg"
              />
            </div>

            {/* Right - Form Section */}
            <div className="w-full md:w-4/7 p-10">
              <h3 className="font-semibold mb-2 text-3xl text-center">
                {title}
              </h3>

              {/* Popup Form */}
              <div className="flex justify-center mt-4 space-x-4">
                <PopupForm buttonText={buttonText} consentText={consentText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
