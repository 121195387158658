'use client';
import React, { useEffect, useState } from 'react';
import Popup from '@/components/Popup';

interface PopupSecondsProps {
  delaySeconds?: number;
  title?: string;
  heading?: string;
  imageSrc?: string;
  buttonText?: string;
  consentText?: string;
}

const PopupSeconds: React.FC<PopupSecondsProps> = ({
  delaySeconds = 3,
  title = 'Welcome to WalkInEducate',
  heading = '',
  imageSrc = '/images/business-women.png',
  buttonText = 'Claim Your Discount',
  consentText = 'text-gray-700',
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    const lastClosedPopup = localStorage.getItem('lastClosedPopup');

    // Only show the popup if it hasn't been closed this month
    if (!lastClosedPopup || parseInt(lastClosedPopup) !== currentMonth) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, delaySeconds * 1000); // delaySeconds converted to milliseconds

      return () => clearTimeout(timer); // Clear the timer on component unmount
    }
  }, [delaySeconds]);

  const handleClose = () => {
    // Set the current month in localStorage when popup is closed
    const currentMonth = new Date().getMonth();
    localStorage.setItem('lastClosedPopup', currentMonth.toString());
    setIsVisible(false);
  };

  return (
    <div>
      {isVisible && (
        <Popup
          isVisible={isVisible}
          onClose={handleClose}
          title={title}
          buttonText={buttonText}
          consentText={consentText}
        />
      )}
    </div>
  );
};

export default PopupSeconds;
